<template>
  <div id="cards">
    <div class="card-item">
      <div class="card-header">
        <div class="card-header-left">企业类型统计</div>
        <!-- <div class="card-header-right">{{ '0' + (i + 1) }}</div> -->
      </div>
      <dv-active-ring-chart class="ring-charts" :config="config" style="width:100%;height:300px" />
      
    </div>
    <div class="card-item" style="width:25%">
      <div class="card-header">
        <div class="card-header-left">各市企业占比</div>
        
      </div>
      <dv-active-ring-chart class="ring-charts" :config="config2" style="width:100%;height:300px" />
      
    </div> 
    <div class="card-item" style="width:48%">
      <div class="card-header">
        <div class="card-header-left">企业注册年变化趋势</div>
        <!-- <div class="card-header-right">{{ '0' + (i + 1) }}</div> -->
      </div>
      <dv-charts :option="option" height="300px" style="width:100%;height:300px" />
      <!-- <ve-histogram :data="chartData" height="300px" :extend="extend"  style="width:100%;height:200px"></ve-histogram> -->
      
    </div>
   
  </div>
</template>

<script>
export default {
  name: 'Cards',
  data () {
    this.extend = {
        series: {
          label: { show: true, position: "top" }
        }
      }
    return {
     option:[],
      cards: [],
      config:{},
      config2:{},
      config3:{},
      chartData:{}

    }
  },
  methods: {
   async createData () {
     
    //gfcType
      const {data:res} =await this.$http.post('gfcType')
                res.data.forEach((item) => {
                  item.value = parseInt(item.value)
                });
                this.config ={
                  data:res.data,
                  radius:80,
                  // activeRadius:90,
                  // showOriginValue:true,
                  color:['rgb(255,219,92)','rgb(159,230,184)','rgb(103,224,227)']
                  // rowNum:response.data.total

                }
                console.log(this.config.data)
          
            const {data:res2} = await this.$http.post('qyCount')
            let listData = []
            let year = []
                 for(let i=0;i<res2.data.length;i++){
                  year.push(2017+i)
                  listData.push(res2.data[i])
                }
                console.log(year)
                console.log(listData)
                // this.option.xAxis.data=year
                // this.option.series.data=listData
                let option1= {
        legend: {
          data: ['企业注册'],
          textStyle: {
            fill: '#fff'
          }
        },
        xAxis: {
          data: year,
          boundaryGap: false,
          axisLine: {
            style: {
              stroke: '#999'
            }
          },
          axisLabel: {
            style: {
              fill: '#999'
            }
          },
          axisTick: {
            show: false
          }
        },
        yAxis: {
          data: 'value',
          splitLine: {
            show: false
          },
          axisLine: {
            style: {
              stroke: '#999'
            }
          },
          axisLabel: {
            style: {
              fill: '#999'
            },
            formatter ({ value }) {
              return value
            }
          },
          axisTick: {
            show: false
          },
          // min: 95,
          // max: 100,
          // interval: 0.5
        },
        series: [
          {
            data:listData,
            type: 'line',
            name: '企业注册',
            smooth: true,
            lineArea: {
              show: true,
              gradient: ['rgba(55, 162, 218, 0.6)', 'rgba(55, 162, 218, 0)']
            },
            linePoint: {
              radius: 4,
              style: {
                fill: '#00db95'
              }
            }
          }
        ]
      }
      this.option=option1
             
    },
   async disData(){
        const {data:res} = await this.$http.post('qyDistribute');
        console.log(res)
        let rank ={}
        rank.data=res.data
        rank.rowNum =res.data.length
        this.config2=rank
    }
  },
  mounted () {
    const { createData,disData } = this
    createData()
    disData()
    setInterval(this.createData, 30000)
    setInterval(this.disData, 30000)
  }
}
</script>

<style lang="less">
#cards {
  display: flex;
  justify-content: space-between;
  height: 45%;

  .card-item {
    background-color: rgba(6, 30, 93, 0.5);
    border-top: 2px solid rgba(1, 153, 209, .5);
    width: 24%;
    display: flex;
    flex-direction: column;
  }

  .card-header {
    display: flex;
    height: 20%;
    align-items: center;
    justify-content: space-between;

    .card-header-left {
      font-size: 18px;
      font-weight: bold;
      padding-left: 20px;
    }

    .card-header-right {
      padding-right: 20px;
      font-size: 40px;
      color: #03d3ec;
    }
  }

  .ring-charts {
    height: 45%;
  }

  .card-footer {
    height: 25%;
    display: flex;
    align-items: center;
    justify-content: space-around;
  }

  .card-footer-item {
    padding: 5px 10px 0px 10px;
    box-sizing: border-box;
    width: 40%;
    background-color: rgba(6, 30, 93, 0.7);
    border-radius: 3px;

    .footer-title {
      font-size: 15px;
      margin-bottom: 5px;
    }

    .footer-detail {
      font-size: 20px;
      color: #1294fb;
      display: flex;
      font-size: 18px;
      align-items: center;

      .dv-digital-flop {
        margin-right: 5px;
      }
    }
  }
}
</style>
