<template>
  <div id="scroll-board">
    <dv-scroll-board :config="config" />
  </div>
</template>

<script>
export default {
  name: 'ScrollBoard',
  data () {
    return {
      config: {
       
      }
    }
  },
  mounted () {
    const { getload } = this

    getload()

    setInterval(getload, 30000)
  },
  methods:{
    async getload(){
      try {
        const {data:res} = await this.$http.post('zsmxx')
        const listData = []
                res.data.forEach((item) => {
                 listData.push([item.j_time,item.j_scsmc,1])
                });
                this.config ={
                  header: ['时间', '企业', '数量'],
                  data:listData,
                  // rowNum:response.data.total
                  index: true,
                  columnWidth: [50, 170, 300],
                  align: ['center'],
                  rowNum: 7,
                  headerBGC: '#1981f6',
                  headerHeight: 45,
                  oddRowBGC: 'rgba(0, 44, 81, 0.8)',
                  evenRowBGC: 'rgba(10, 29, 50, 0.8)'

                }
      } catch (error) {
        
      }
      
    }
  }
}
</script>

<style lang="less">
#scroll-board {
  width: 58%;
  box-sizing: border-box;
  margin-left: 20px;
  height: 100%;
  overflow: hidden;
}
</style>
