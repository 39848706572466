<template>
  <div id="top-header">
    <dv-decoration-8 class="header-left-decoration" />
    <dv-decoration-5 class="header-center-decoration" />
    <dv-decoration-8 class="header-right-decoration" :reverse="true" />
    <div class="center-title">种子溯源大数据</div>
    <!-- <button>追溯码大数据</button> -->
    <el-row  :gutter="20" style="width:800px;margin-top:40px" >
      <el-col :span="6"><el-button class="button1" @click="toPinzhong">种子品种数据库</el-button></el-col>
  <el-col :span="6"><el-button class="button" @click="tosym">追溯码大数据</el-button></el-col>
  <el-col :span="6"><el-button class="button" @click="toqy">企业注册管理</el-button></el-col>
  <el-col :span="6"><el-button class="button3" @click="tologin">退出</el-button></el-col>
 
    </el-row>
    
    
  </div>
</template>

<script>
export default {
  name: 'TopHeader',
  methods:{
    toPinzhong(){
      this.$router.push('/admin/pinzhong')
    },
    tosym(){
      this.$router.push('/admin/smtz')
    },
    toqy(){
      this.$router.push('/admin/register')
    },
    tologin(){
      this.$router.push('/login')
    }
  }
}
</script>

<style lang="less">
#top-header {
  position: relative;
  width: 100%;
  height: 100px;
  display: flex;
  justify-content: space-between;
  flex-shrink: 0;

  .header-center-decoration {
    width: 40%;
    height: 60px;
    margin-top: 30px;
  }

  .header-left-decoration, .header-right-decoration {
    width: 25%;
    height: 60px;
  }

  .center-title {
    position: absolute;
    font-size: 30px;
    font-weight: bold;
    left: 50%;
    top: 15px;
    transform: translateX(-50%);
  }
  .button{
    // position: absolute;
    // right: 24%;
    background: none;
    color: #fff;
    top: 40px;
  }
  .button1{
    // position: absolute;
    // right: 16%;
    top: 40px;
    background: none;
    color: #fff;
  }
  .button3{
    // position: absolute;
    // right: 10%;
    top: 40px;
    background: none;
    color: #fff;
  }
}
</style>
