<template>
  <div id="ranking-board">
    <div class="ranking-board-title">各市企业数量排行</div>
    <dv-scroll-ranking-board :config="config"  />
    <!-- <ve-histogram :data="chartData"  height="300px" :extend="extend"  style="width:100%;height:200px;margin-buttom:50px"></ve-histogram> -->
      <!-- <div class="ranking-board-title title2">数据实时上传</div>
      <ve-map :data="chartData" :settings="chartSettings" style="padding-left:10%"  width="80%" height="100%"></ve-map> -->
  </div>
</template>

<script>
export default {
  name: 'RankingBoard',
  data () { 
    return {
        
      config: {
        
      }
      ,
    //   chartColor: ['#89dd47', '#3cabf4'],
      chartData:{}
      }
  },
  created(){
    this.createData()
  },
mounted () {
    const { createData } = this
    
    // getcount()
    

    setInterval(createData, 30000)
    // setInterval(getcount, 30000)
  },
  methods: {
      async createData () {
      try {
        const {data:res} = await this.$http.post('qyDistribute');
        console.log(res)
        let rank ={}
        // res.data.forEach((item) => {
        //   this.config.data.push({'name':item.name,'value':parseInt(item.value)})
        //           // item['name']=item.name
        //           // item["value"] = parseInt(item.value)
        //         });
                // this.config.data=res.data
        rank.data=res.data
        rank.rowNum =res.data.length
        this.config=rank
        //         this.chartData ={
        //           columns: ['位置', '企业数量'],
        //           rows: res.data,
        //           // rowNum:response.data.total

        //         }
                console.log(this.config)
      } catch (error) {
        
      }
                
            
    },
  //  async getcount(){
  //      await this.axios.post('http://www.aielab.net/bigdatashow/index.php/home/District/showdata/').then((response)=>{
  //               response.data.rows.forEach((item) => {
  //                 item.value = parseInt(item.value)
  //               });
  //               this.chartData ={
  //                 columns: ["name","value"],
  //                 rows:response.data.rows,

  //               }
  //               console.log(this.chartData.data)
  //           }).catch((response)=>{
  //               console.log(response)
  //           })
  //   }
  }
}
</script>

<style lang="less">
#ranking-board {
  width: 25%;
  box-shadow: 0 0 3px blue;
  display: flex;
  flex-direction: column;
  background-color: rgba(6, 30, 93, 0.5);
  border-top: 2px solid rgba(1, 153, 209, .5);
  box-sizing: border-box;
  padding: 0px 30px;

  .ranking-board-title {
    font-weight: bold;
    height: 50px;
    display: flex;
    align-items: center;
    font-size: 20px; 
  }
.title2{
    margin-top: 50px;
}
  .dv-scroll-ranking-board {
    flex: 1;
  }
}
</style>
